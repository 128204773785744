import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
// import Bodi from './Bodi';
// import Bodi2 from './Bodi2';


class Heda extends React.Component {
    
    render() { 
      
      return (
        
        <footer class="sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>Copyright &copy; Your Website 2020</span>
          </div>
        </div>
      </footer>
        );
    }
  }

  
export default Heda;