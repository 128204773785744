import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
// import Bodi from './Bodi';
// import Bodi2 from './Bodi2';

//import Formi from './Formi';
import Lprofile from './Lprofile';

class Heda extends React.Component {
    
    render() { 
      
      return (
        <div className="container-fluid">
          
          <Lprofile/>
        </div>
        
        );
    }
  }

  
export default Heda;