import React, { Fragment } from 'react';
//import DataTable from 'react-data-table-component';
import ReactDatatable from '@ashvin27/react-datatable';
import $ from 'jquery'
import { Button,Modal } from 'react-bootstrap';
import Formi from './Formi';
import Dele from './Dele';
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';



class Lprofile extends React.Component {
  constructor(props) { 
    super(props);
    this.state = {
      mydata: [],
      show: false,
      modalTit: "Add New Record",
      id: '',
      deletFlag:''
    };
    this.config = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        button: {
            excel:true,
            print:true,
            
            extra: true,
            
        }
    }
         
  }
//csv: true,
  fetchUserData = async () => {
    const response = await fetch("/server/disp");
    let  mydata  = await response.json() ;
    //alert(mydata);
    //mydata = JSON.parse(mydata);
    
    this.setState({
      mydata: mydata
    });
    
  }
  componentDidMount() {
      
    this.fetchUserData();
  }
  
  shouldComponentUpdate() {
    return true;
  }
  editRecord(id,vname){
    this.setState({modalTit: "Edit Youth Record -- "+vname , id: id,deletFlag: ''});
    this.setShow(true); 
  }
  deleteRecord(id,vname){
    this.setState({modalTit: "Delete Record -- "+vname , id: id,deletFlag: '1'});

    this.setShow(true); 
  }
  
  // componentDidMount() {
  //   this.setState({mydata:
      
  //   });
  // }
  setShow = (val) => {
    this.setState({ show: val })
  }
  render() {
    //const data1 = this.dis;
    
    //     alert(data)
    
    
    const displayMod = () => {
      const handleClose = () => this.setShow(false);
      const UniqueID = this.state.id
      const fetchUser = this.fetchUserData
      switch(this.state.deletFlag) {

        case "1": const deleinfo = {uid: UniqueID, closeMod: handleClose, fetchUserD : fetchUser };
                  return <Dele deleobjInfo={deleinfo} />;
        
        

        default:     const forminfo = {uid: UniqueID , fetchUserD : fetchUser };
                     return <Formi formObjInfo={forminfo} />;
      }
    }
    const handleClose = () => this.setShow(false);
    const handleShow = () => {this.setShow(true); this.setState({modalTit: "Add New Youth Record", id:''}) }
    
    const columns = [
      {
        text: 'Name',
        key: 'vname',
        sortable: true,
        cell: record => <div>{record.vname}<span style={{fontStyle: 'oblique'}}> ({record.gender}) </span> </div>
              ,
      },
      {
        text: 'Phone Number',
        sortable: true,
        cell: record => <div>{record.phnum}</div>,
      },
      {
        text: 'Email Address',
        sortable: true,
        cell: record => <div>{record.email}</div>,
      },
      {
        text: 'Contact Address',
        sortable: true,
        cell: record => <div>{record.address}</div>,
      },
      {
        text: 'Date of Birth',
        sortable: true,
        cell: record => <div>{Moment(record.dob).format('Do, MMMM YYYY')}</div>,
      },
      {
        text: 'Action',
        sortable: true,
        cell:record => { 
              return (
                  <Fragment>
                      <button
                          className="btn btn-primary btn-sm"
                          onClick={() => this.editRecord(record.id,record.vname)}
                          style={{marginRight: '5px'}}>
                          <FontAwesomeIcon icon={faEdit} />
                      </button>

                      <button 
                          className="btn btn-danger btn-sm" 
                          onClick={() => this.deleteRecord(record.id,record.vname)}>
                          <FontAwesomeIcon icon={faTrash} />
                      </button>
                  </Fragment>
              );
            },
      },  
    ];

     

     //const reFreshTab = this.fetchUserData()
     
      return (
      
    <> 
      <section>
        <div className="container table-responsive">
          <div className="px-2 float-right">
            <Button variant="success"  onClick={handleShow} >ADD</Button>
            <Modal show={this.state.show} onHide={handleClose}  size="lg"
                  backdrop="static"
                  keyboard={false}>
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modalTit}</Modal.Title>
              </Modal.Header>
              <Modal.Body> {displayMod()} </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                
              </Modal.Footer>
            </Modal>
          </div>
            <ReactDatatable
                  title="My DataTable "
                  columns={columns}
                  records={this.state.mydata}
                  config={this.config}
                  selectableRows
                  
                />
        </div> 
        
      </section>
    </>);
    }
  }

  
export default Lprofile;