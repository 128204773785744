import React from 'react';
//import logo from './logo.svg';
//import $ from 'jquery'
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap';
import './App.css';
import './sb-admin-2.css';

import 'react-fontawesome';

///js

import Sidebar from './Sidebar';

import Heda from './Heda';
import Fota from './Fota';
import Content from './Content';
import Mod_Scr from './Mod_Scr';

 //import AddToHomeScreen from '@ideasio/add-to-homescreen-react'
//import Foota from './Foota';



class App extends React.Component {
  render(){ 
    return(
      <div>
        <div id="wrapper">
          <Sidebar/>
          
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Heda/>
              <Content/>
            </div>
            <Fota/>
          </div>
          
        </div>
        <Mod_Scr/>
        
      </div>
    );
  } 
}




export default App;

