import React from 'react';
import {Spinner} from 'react-bootstrap'
import $ from 'jquery'





class Dele extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      vname: '',
      gender: '',
      email:'',
      address: '',
      dob:'',
      phnum:'',
      vname_errormessage: '',
      age_errormessage: '',
      gender_errormessage: '',
      phnum_errormessage: '',
      email_errormessage: '',
      id:this.props.deleobjInfo.uid,
      displ: 'display: none',
      displ2: 'display: block'
    };
    //this.props.uid='';
  }
 
 eRR(msg) {
    return <div className="alert alert-danger " style={{display: "flex"}} ><strong>{msg}</strong></div>;
 }
 

 
  componentDidMount() {
    document.querySelector('#cont').style.display ='block'
    document.querySelector('#spin').style.display ='none'

  }


  

  mySubmitHandler = (event) => {
    event.preventDefault();
   
   
    this.subm(event);
    this.props.deleobjInfo.fetchUserD()
    //alert("You are submitting " + this.state.vname);
  }

  subm =(event)=>{  
    document.querySelector('#mmsg').innerHTML='';
     event.preventDefault()
    var lform = document.querySelector("#myform")
    var error=''
    var x = lform.elements.length
    document.querySelector('#sub').style.display ='none'
    document.querySelector('#cont').style.display ='none'
    document.querySelector('#spin').style.display ='block'
    
    if(error===''){
        //document.querySelector('#loadd').innerHTML= '<div align="center" id="overlay" ><div class="loader"></div><em style="font-size: 5em; color:red">Please wait... </em></div>';
        //return
        var formData = new FormData(lform);
        
        $.ajax({
        url: "/server/del",
        type:"POST",
        data: formData,
        success:
            function(data){
              if(data === '1'){
                
                  document.querySelector('#mydiv').innerHTML='<div  class="alert  alert-primary alert-dismissible" role="alert"><button type="button"  class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>Record Successfully Deleted!.</div>'

               
                
                //this.props.onRefreshDataTable()
              }else {
                document.querySelector('#mmsg').innerHTML=data;
                
              }
             
              //document.querySelector('#loadd').innerHTML='';
              console.log('Successful ');
              document.querySelector('#cont').style.display ='block'
              document.querySelector('#spin').style.display ='none'
              if(document.querySelector('#sub'))document.querySelector('#sub').style.display ='block'

            },
            contentType: false, //this is requireded please see answers above
            processData: false, //this is requireded please see answers above
            
        })
        .fail(()=> {
          document.querySelector('#mmsg').innerHTML+="<br><span class='text-danger small'>Error Connecting ...<br></span>";
          console.log('Error');
          document.querySelector('#cont').style.display ='block'
          document.querySelector('#spin').style.display ='none'
          if(document.querySelector('#sub'))document.querySelector('#sub').style.display ='block'

          //document.querySelector('#loadd').innerHTML='';
        })
        
    }
    
       

  }

  closeMyMod=()=> {
    this.props.deleobjInfo.closeMod()
  }
  render() {
    
      return (<> 
        
       
        <section>
          <div className="text-center row " style={{marginLeft: '20em'}} >

            <Spinner animation="border" className="text-danger "  style={{fontSize: '20em'}} id="spin" size="sm" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        
          <div className="container" style={{display: 'none'}} id="cont" >
            <div className="row align-items-center">
            
              <div className="col-lg-12 card order-lg-1">
                <div className="p-3" >
                  
                  <form  method="post" id="myform">
                    <div id="mydiv">
                      
                      
                      <span id="mmsg"></span> 
                      <input type="hidden" id="uid" name="uid" value={this.state.id} />

                      <div className="row">
                        <div className="col-sm-12">
                            <h3>Are you sure you want to delete this record ?</h3>
                        </div>
                        <div className="col-sm-6 text-left">
                            <button className="btn btn-danger btn-sm mt-3" id="sub" onClick={this.mySubmitHandler} >
                            YES  
                          </button>
                        </div>
                        <div className="col-sm-6 text-right">
                            <button type="button" onClick={this.closeMyMod} className="btn btn-primary  btn-sm mt-3 "  >
                             <span className="close">NO</span>  
                            </button>
                        </div>
                        
                      </div>
                      
                      

                    </div>
                    
                  </form>
                </div>
              </div>
            </div>
          </div>
      </section>
     
       
      </>);
    }
  }

  
export default Dele;