import React from 'react';
import {Spinner} from 'react-bootstrap'
import $ from 'jquery'





class Formi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      vname: '',
      gender: '',
      email:'',
      address: '',
      dob:'',
      phnum:'',
      vname_errormessage: '',
      age_errormessage: '',
      gender_errormessage: '',
      phnum_errormessage: '',
      email_errormessage: '',
      id:this.props.formObjInfo.uid,
      displ: 'display: none',
      displ2: 'display: block'
    };
    //this.props.uid='';
  }
 
 eRR(msg) {
    return <div className="alert alert-danger " style={{display: "flex"}} ><strong>{msg}</strong></div>;
 }
 

 fetchUserData1 = async () => {
    let myinp = new FormData();
    let val = this.state.id;
    myinp.append('id',val)
    const response = await fetch("/server/getData",{
      method:'POST',
      body: myinp
    });
    let  mydata  = await response.json() ;
    //mydata = JSON.stringify(mydata);
    //alert(mydata[0].vname);
    
    this.setState({
      vname: mydata[0].vname,
      gender: mydata[0].gender,
      email: mydata[0].email,
      address:  mydata[0].address,
      dob: mydata[0].dob,
      phnum: mydata[0].phnum,
    });
    if(document.querySelector('#cont')){
      document.querySelector('#cont').style.display ='block'
      document.querySelector('#spin').style.display ='none'
    }
   
   
  }
  componentDidMount() {
    document.querySelector('#cont').style.display ='none'
    document.querySelector('#spin').style.display ='block'

    if(this.state.id !== ''){
      this.fetchUserData1();
      //this.setState({ displ2: 'display: none',displ: 'display: block' });
      
    }else{
      document.querySelector('#cont').style.display ='block'
      document.querySelector('#spin').style.display ='none'
    }
    
    //alert(this.state.vname)
  }


  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    let err='';
    switch(nam){
        case "dob" :    if (val ==="" || !Date(val)) {
                            err = this.eRR("Your date of birth must be valid");
                            // this.setState({age_errormessage: err});
                        }else{
                            err='';
                        }
                        this.setState({age_errormessage: err});
                        break;
        case "gender" :    if (val ==="") {
                            err = this.eRR("You must choose your Gender");
                                // this.setState({age_errormessage: err});
                            }else{
                                err='';
                            }
                            this.setState({gender_errormessage : err});
                            break;
        case "vname" :    let regName = /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
                            if (!regName.test(val)) {
                                err = this.eRR("You must type a full name");
                                // this.setState({age_errormessage: err});
                            }else{
                                err='';
                            }
                            this.setState({vname_errormessage : err});
                            break;
        case "phnum" :    
                            if (val ==="" || !Number(val) || val.trim().length < 10) {
                                err = this.eRR("You must type a valid phone number");
                                // this.setState({age_errormessage: err});
                            }else{
                                err='';
                            }
                            this.setState({phnum_errormessage : err});
                            break;
        case "email" :     //alert()
                            let regEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
                            if (val !=="" && !regEmail.test(val)) {
                                err = this.eRR("You must type a valid email address or leave it blank");
                                // this.setState({age_errormessage: err});
                            }else{
                                err='';
                            }
                            this.setState({email_errormessage : err});
                            break;
                          
    }
    this.setState({[nam] : val});
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
   
    if(this.state.gender_errormessage !=='' || this.state.age_errormessage != '' || this.state.phnum_errormessage != '' || 
    this.state.email_errormessage != '' || this.state.vname_errormessage != '' ){
      return alert("Unable to Submit Please check your Input Fields!");
    }
    this.subm(event);
    //alert("You are submitting " + this.state.vname);
    this.props.formObjInfo.fetchUserD()
  }

  subm =(event)=>{  
    document.querySelector('#mmsg').innerHTML='';
     event.preventDefault()
    var lform = document.querySelector("#myform")
    var error=''
    var x = lform.elements.length
    document.querySelector('#sub').style.display ='none'
    document.querySelector('#cont').style.display ='none'
    document.querySelector('#spin').style.display ='block'
    for(var i=0;i<x;i++){
        if(lform.elements[i].type==='tel'){
            //alert('Tel')
            if(lform.elements[i].value.trim()!==''){ //alert(lform.elements[i].value)
                var elemm = parseInt(lform.elements[i].value)
                var elemmstr =elemm.toString()
                //alert(elemmstr.length)
                
                if(elemmstr.length < 10 ){ //isNaN()
                    lform.elements[i].style.borderColor='pink'
                    document.querySelector('#mmsg').innerHTML='<div class="alert alert-danger alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>Please Fill the Empty Fields!</div>'
                    error=1
                }else{
                    lform.elements[i].style.borderColor=''
                }
                
            }else{
                lform.elements[i].style.borderColor=''
            }
        }
        if(lform.elements[i].required){
            if(lform.elements[i].value===''){
                lform.elements[i].style.borderColor='red'
                //alert(lform.elements[i].id)
                document.querySelector('#mmsg').innerHTML='<div id="als" class="alert alert-danger alert-dismissible" role="alert"><button type="button" onclick="document.querySelector('+"'#als'"+').style.display='+"'none';"+'" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>Please Fill the Empty Fields!</div>'
                error=1
            }else{
                lform.elements[i].style.borderColor=''
            }
        }
        
        document.querySelector('#cont').style.display ='block'
        document.querySelector('#spin').style.display ='none'
        document.querySelector('#sub').style.display ='block'
    }
    if(error===''){
        //document.querySelector('#loadd').innerHTML= '<div align="center" id="overlay" ><div class="loader"></div><em style="font-size: 5em; color:red">Please wait... </em></div>';
        //return
        var formData = new FormData(lform);
        
        $.ajax({
        url: "/server/subm",
        type:"POST",
        data: formData,
        success:
            function(data){
              if(data === '1'){
                if(document.querySelector('#uid').value === ''){
                  document.querySelector('#mydiv').innerHTML='<div  class="alert  alert-primary alert-dismissible" role="alert"><button type="button"  class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>Record Successfully Saved!.</div>'

                }else{
                  document.querySelector('#mydiv').innerHTML='<div  class="alert  alert-primary alert-dismissible" role="alert"><button type="button"  class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>Record Successfully Updated!.</div>'

                }
                
                //this.props.onRefreshDataTable()
              }else {
                if(document.querySelector('#mmsg'))document.querySelector('#mmsg').innerHTML=data;
                
              }
             
              //document.querySelector('#loadd').innerHTML='';
              console.log('Successful ');
              document.querySelector('#cont').style.display ='block'
              document.querySelector('#spin').style.display ='none'
              if(document.querySelector('#sub'))document.querySelector('#sub').style.display ='block'

            },
            contentType: false, //this is requireded please see answers above
            processData: false, //this is requireded please see answers above
            
        })
        .fail(()=> {
          document.querySelector('#mmsg').innerHTML+="<br><span class='text-danger small'>Error Connecting ...<br></span>";
          console.log('Error');
          document.querySelector('#cont').style.display ='block'
          document.querySelector('#spin').style.display ='none'
          document.querySelector('#sub').style.display ='block'

          //document.querySelector('#loadd').innerHTML='';
        })
        
    }
    
       

  }

  render() {
    
      return (<> 
        
       
        <section>
          <div className="text-center row " style={{marginLeft: '20em'}} >

            <Spinner animation="border" className="text-danger "  style={{fontSize: '20em'}} id="spin" size="sm" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        
          <div className="container" style={{display: 'none'}} id="cont" >
            <div className="row align-items-center">
            
              <div className="col-lg-12 card order-lg-1">
                <div className="p-3" >
                  
                  <form  method="post" id="myform">
                    <div id="mydiv">
                      
                      
                      <span id="mmsg"></span> 
                      <div className="row">
                        <div className="col-sm-6">
                            <input type="hidden" id="uid" name="uid" onChange={this.myChangeHandler}  value={this.state.id} />
                            <label class="req"> Name:</label>
                            <input type="text" required name="vname" value={this.state.vname} required onChange={this.myChangeHandler} className="form-control" />
                            {this.state.vname_errormessage}
                            <label class="req"> Date of Birth:</label>
                            <input type="date" required name="dob" value={this.state.dob} required className="form-control" onChange={this.myChangeHandler} />
                            {this.state.age_errormessage}
                            <label class="req"> Gender:</label>
                            <select className="form-control" name="gender" value={this.state.gender} required onChange={this.myChangeHandler} >
                              <option value="">Please Select</option>
                              <option value="M" >Male </option>
                              <option value="F">Female</option>
                            </select>
                            {this.state.gender_errormessage}
                        </div>
                        <div className="col-sm-6">
                            <label> Address:</label>
                            <textarea className="form-control" onChange={this.myChangeHandler} value={this.state.address} name="address"  />
                            <label class="req"> Phone Number:</label> 
                            <input className="form-control" required value={this.state.phnum} onChange={this.myChangeHandler} name="phnum" type="tel"  />
                            {this.state.phnum_errormessage}
                            <label> Email Address:</label>
                            <input className="form-control" value={this.state.email} onChange={this.myChangeHandler} name="email" type="email"  />
                            {this.state.email_errormessage}
                        </div>
                        <div className="col-sm-12">
                          <button className="btn btn-primary  btn-block mt-3" id="sub" onClick={this.mySubmitHandler} >
                            { this.state.id === ''? 'Submit': 'Update'}  
                          </button>
                                
                        </div>
                      </div>
                      
                      

                    </div>
                    
                  </form>
                </div>
              </div>
            </div>
          </div>
      </section>
     
       
      </>);
    }
  }

  
export default Formi;