import React from 'react';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom";
// import Bodi from './Bodi';
// import Bodi2 from './Bodi2';
import {Collapse} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench, faLaughWink, faTachometerAlt,faCog, faFolder, faChartArea, faTable } from '@fortawesome/free-solid-svg-icons';

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          open: false,
          open2: false,
          open3: false,
          
          
        };
    }
    changeState = (op) => {
        return !op;
    }
    render() { 
        
      return (
        
        
            <>   
          
                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" style={{display:'none'}} id="accordionSidebar">

                    
                    <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                    <div className="sidebar-brand-icon rotate-n-15">
                        
                        <FontAwesomeIcon icon={faLaughWink} />
                        
                        
                    </div>
                    <div className="sidebar-brand-text mx-3">SB Admin <sup>2</sup></div>
                    </a>

                    
                    <hr className="sidebar-divider my-0"/>

                    
                    <li className="nav-item active">
                    <a className="nav-link" href="index.html">
                        
                        <FontAwesomeIcon icon={faTachometerAlt} />
                        <span>Dashboard</span></a>
                    </li>

                    
                    <hr className="sidebar-divider"/>

                    
                    <div className="sidebar-heading">
                    Interface
                    </div>

                    
                    <li className="nav-item">
                        <a onClick={() => this.setState({open: this.changeState(this.state.open)})}
                            aria-controls="collapse1"
                            aria-expanded={this.state.open} className="nav-link collapsed" href="#" >
                            
                            <FontAwesomeIcon icon={faCog} />
                            <span> Components</span>
                        </a>
                        
                        <Collapse in={this.state.open}>
                            <div id="collapse1">
                                <div className="bg-white py-2 collapse-inner rounded">
                                <h6 className="collapse-header">Custom Components:</h6>
                                <a className="collapse-item" href="buttons.html">Buttons</a>
                                <a className="collapse-item" href="cards.html">Cards</a>
                                </div>
                            </div>
                        </Collapse>
                    
                   
                    </li>

                    
                    <li className="nav-item">
                    <a onClick={() => this.setState({open2: this.changeState(this.state.open2)})}
                            aria-controls="collapse2"
                            aria-expanded={this.state.open2} className="nav-link collapsed" href="#">
                        <FontAwesomeIcon icon={faWrench} />
                        <span>Utilities</span>
                    </a>
                    <Collapse in={this.state.open2}>
                        <div id="collapse2">
                            <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Custom Utilities:</h6>
                            <a className="collapse-item" href="utilities-color.html">Colors</a>
                            <a className="collapse-item" href="utilities-border.html">Borders</a>
                            <a className="collapse-item" href="utilities-animation.html">Animations</a>
                            <a className="collapse-item" href="utilities-other.html">Other</a>
                            </div>
                        </div>
                    </Collapse>
                    </li>

                
                    <hr className="sidebar-divider"/>

                    
                    <div className="sidebar-heading">
                        Addons
                    </div>

                
                    <li className="nav-item">
                    <a onClick={() => this.setState({open3: this.changeState(this.state.open3)})}
                            aria-controls="collapse3"
                            aria-expanded={this.state.open3} className="nav-link collapsed" href="#">
                        <FontAwesomeIcon icon={faFolder} />
                        <span>Pages</span>
                    </a>
                    <Collapse in={this.state.open3}>
                        <div id="collapse3">
                            <div className="bg-white py-2 collapse-inner rounded">
                            <h6 className="collapse-header">Login Screens:</h6>
                            <a className="collapse-item" href="login.html">Login</a>
                            <a className="collapse-item" href="register.html">Register</a>
                            <a className="collapse-item" href="forgot-password.html">Forgot Password</a>
                            <div className="collapse-divider"></div>
                            <h6 className="collapse-header">Other Pages:</h6>
                            <a className="collapse-item" href="404.html">404 Page</a>
                            <a className="collapse-item" href="blank.html">Blank Page</a>
                            </div>
                        </div>
                        
                    </Collapse>
                    </li>

                    
                    <li className="nav-item">
                    <a className="nav-link" href="charts.html">
                        <FontAwesomeIcon icon={faChartArea} />
                        <span>Charts</span></a>
                    </li>

                    
                    <li className="nav-item">
                    <a className="nav-link" href="tables.html">
                        <FontAwesomeIcon icon={faTable} />
                        
                        <span>Tables</span></a>
                    </li>

                    
                    <hr className="sidebar-divider d-none d-md-block"/>

                    
                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>

                </ul>
            
            </>
        
        );
    }
  }

  
export default Sidebar;